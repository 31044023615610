import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`


/*
.tk-gopher { font-family: "gopher",sans-serif; }

    body{
        font-family: gopher, sans-serif;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
        font-size: 16px;
        font-weight: 500;
        position: relative;
        min-height: 100vh ;
    }
    a {
        text-decoration: none;
    }
    */
`;

export default GlobalStyles;
