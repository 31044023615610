import React from "react";
import GlobalStyles from "./global-styles";
import "./src/assets/fonts/fonts.css";
import "./src/assets/css/static.css";

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <GlobalStyles />
      {element}
    </>
  );
};

/*
export const onRenderBody = ({ setHeadComponents }) => {
    setHeadComponents([
      <link
        key="Geom-Light"
        rel="preload"
        href="/fonts/Geom-Light.ttf"
        as="font"
        type="font/ttf"
        crossOrigin="anonymous"
      />,
      <link
        key="Geom-Ultra"
        rel="preload"
        href="/fonts/Geom-Ultra.ttf"
        as="font"
        type="font/ttf"
        crossOrigin="anonymous"
      />,
      <link
        key="Geom-Variable"
        rel="preload"
        href="/fonts/Geom-Variable.ttf"
        as="font"
        type="font/ttf"
        crossOrigin="anonymous"
      />
    ]);
  };
*/
