exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-business-tsx": () => import("./../../../src/templates/business.tsx" /* webpackChunkName: "component---src-templates-business-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-consulting-tsx": () => import("./../../../src/templates/consulting.tsx" /* webpackChunkName: "component---src-templates-consulting-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-offers-tsx": () => import("./../../../src/templates/offers.tsx" /* webpackChunkName: "component---src-templates-offers-tsx" */),
  "component---src-templates-outsourcing-tsx": () => import("./../../../src/templates/outsourcing.tsx" /* webpackChunkName: "component---src-templates-outsourcing-tsx" */),
  "component---src-templates-podcast-tsx": () => import("./../../../src/templates/podcast.tsx" /* webpackChunkName: "component---src-templates-podcast-tsx" */),
  "component---src-templates-products-tsx": () => import("./../../../src/templates/products.tsx" /* webpackChunkName: "component---src-templates-products-tsx" */),
  "component---src-templates-recruitment-tsx": () => import("./../../../src/templates/recruitment.tsx" /* webpackChunkName: "component---src-templates-recruitment-tsx" */),
  "component---src-templates-services-tsx": () => import("./../../../src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */),
  "component---src-templates-software-tsx": () => import("./../../../src/templates/software.tsx" /* webpackChunkName: "component---src-templates-software-tsx" */),
  "component---src-templates-ux-ui-tsx": () => import("./../../../src/templates/ux-ui.tsx" /* webpackChunkName: "component---src-templates-ux-ui-tsx" */)
}

